'use strict';

/**
* Function that toggles the visibility of the dropdown and changes the associated icon.
* @param {jQuery} closestDropdown - The jQuery object representing the closest dropdown element.
*
* @function toggleDropdown
*/
function toggleDropdown(closestDropdown) {
    closestDropdown.toggleClass('d-none');
    var $icon = closestDropdown.find('button i');
    $icon.toggleClass('flipped');
    if (closestDropdown.length > 0 && $('i.icon.i-prefixes').length > 0) {
        if ($('i.icon.i-prefixes').hasClass('flipped')) {
            $('i.icon.i-prefixes').removeClass('flipped');
        } else {
            $('i.icon.i-prefixes').addClass('flipped');
        }
    }
}

/**
 * Selects an option and updates the prefix button text and dropdown visibility.
 *
 * @param {HTMLElement} option - The selected option element.
 * @returns {void}
 */
function selectOption(option) {
    var prefixDropdown = $(option).closest('.dropdown');
    var prefixButton = prefixDropdown.find('#myDropdownButton')[0];
    var textNode = prefixButton.firstChild;
    var newTextNode = document.createTextNode($(option).text().trim());
    prefixButton.replaceChild(newTextNode, textNode);

    var previousSelectedOption = prefixDropdown.find('.dropdown-item.selected')[0];
    if (previousSelectedOption) {
        previousSelectedOption.classList.remove('selected');
    }

    $(option).addClass('selected');
    var countryCode = $(option).text().trim().match(/\((\+\d+)\)/);

    if (countryCode && countryCode.length > 1) {
        var countryCodeValue = countryCode[1];
        if (prefixDropdown[0].id === 'registrationPhonePrefixDropdown') {
            $('.register-phone-prefix').val(countryCodeValue);
        }
        else if (prefixDropdown[0].id === 'phoneBraceletPrefixDropdown') {
            $('.phoneBraceletPrefix.phonePrefix').val(countryCodeValue);
        }
        else if (prefixDropdown[0].id === 'newAddressPhoneDropdown') {
            $('.shipmentPhonePrefix.phonePrefix').val(countryCodeValue);
        }
        else if (prefixDropdown[0].id === 'updatepPrefixDropdown') {
            $('.updatePhonePrefix.phonePrefix').val(countryCodeValue);
        }
        else if (prefixDropdown[0].id === 'registerPhoneDropdown') {
            $('#registrationPhonePrefix').val(countryCodeValue);
        }
        else if (prefixDropdown[0].id === 'shippingPhoneDropdown') {
            $('.shippingPhoneNumberPrefix').val(countryCodeValue);
        }
    }
}

/**
 * Gets the country code from the phonePrefix input value.
 *
 * @function
 * @returns {string|null} The country code (e.g., '+34') or null if not found.
 */
function getCountryCodeFromInput() {
    var input = null;

    if ($('#prefixPhone').length) {
        input = $('#prefixPhone').val().trim();
    } else if ($('#phoneBraceletPrefix').length) {
        input = $('#phoneBraceletPrefix').val().trim();
    } else if ($('#phonePrefix').length) {
        input = $('#phonePrefix').val().trim();
    }

    return input;
}

/**
 * Selects the dropdown option whose text includes the provided country code.
 *
 * @function
 * @param {string} countryCode - The country code to search for in the dropdown.
 * @returns {void}
 */
function selectCountryOptionByCode(countryCode) {
    var dropdownMenu = $('.dropdown-menu');
    var dropdownItems;
    var regex = new RegExp('\\(\\+' + countryCode + '\\)$');
    var selected = false;

    dropdownMenu.each(function () {
        dropdownItems = $(this).find('.dropdown-item');
        dropdownItems.each(function () {
            if (!selected && regex.test($(this).text().replace(/\s/g, ''))) {
                selectOption($(this));
                selected = true;
            }
        });
        selected = false;
    });
}

module.exports = {

    onLoad: function() {
        $(document).ready(function() {
            var countryCode = getCountryCodeFromInput();

            if (countryCode) {
                selectCountryOptionByCode(countryCode);
            } else {
                $('#prefixError').closest('div.invalid-feedback').css('display', 'block');
            }
        });
    },

    closeModalWhenClickOutside: function () {
        $(document).on('click', function (e) {
            var dropdown = $('#myDropdown:not(.d-none)');
            var dropdownButton = dropdown.parent().find('#myDropdownButton');
            var target = $(e.target);

            if (
                !target.is(dropdownButton) &&
                !target.closest(dropdownButton).length &&
                !target.is(dropdown) &&
                !target.closest(dropdown).length &&
                !dropdown.hasClass('d-none')
            ) {
                toggleDropdown(dropdown);
            }
        });
    },

    showPrefixes: function () {
        $('body').on('click', '#myDropdownButton', function (e) {
            e.preventDefault();
            var closestDropdown = $(this).closest('.dropdown').find('#myDropdown');
            toggleDropdown(closestDropdown);
        });
    },

    filterFunction: function() {
        $('#myInput, #myInputBracelet').on('keyup', function (e) {
            e.preventDefault();
            var txtValue;
            var input = $(this);
            var filter = input.val().toUpperCase();
            var div = input.closest('#myDropdown');
            var a = div.find('a');
            for (var i = 0; i < a.length; i++) {
                txtValue = a[i].textContent || a[i].innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    $(a[i]).removeClass('d-none');
                } else {
                    $(a[i]).addClass('d-none');
                }
            }
            if ($('.dropdown-item.text-dark.d-none').length === $('.dropdown-item.text-dark').length) {
                $('.prefix-no-results').removeClass('d-none');
            } else {
                $('.prefix-no-results').addClass('d-none');
            }
        });
    },

    selectOptions: function () {
        var dropdownOptions = document.querySelectorAll('.dropdown-item');
        dropdownOptions.forEach(function(option) {
            option.addEventListener('click', function(e) {
                e.preventDefault();
                selectOption(option);
                var prefixDropdown = $(option).closest('#myDropdown');
                toggleDropdown(prefixDropdown);
            });
        });
    },

    /**
     * Checks if a given phone number is valid as per the specified prefix and mobile phone type.
     * @param {string} phone - The phone number to validate.
     * @param {string} prefix - The prefix to prepend to the phone number before validation.
     * @param {boolean} bolValidateMobilePhone - if true, we must validate mobile phones.
     * @param {boolean} bolValidateFixedPhone - if true, we must validate fixed line phones.
     * @returns {boolean} True if the phone number is valid for the specified prefix and is of mobile phone type, false otherwise.
     */
    validateFields: function (phone, prefix, bolValidateMobilePhone, bolValidateFixedPhone) {
        var result = true;

        // Get an instance of `PhoneNumberUtil`.
        const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
        const phoneTypes = require('google-libphonenumber').PhoneNumberType;
        result = false;
        var parsedNumber = parseInt(phone, 10);

        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(parsedNumber) && parsedNumber.toString().length > 1) {
            // To work with the phone number, it must be a PhoneNumber object, by default parse it in spanish format
            const number = phoneUtil.parseAndKeepRawInput(prefix + phone, 'ES');
            var isValidMobile = true;
            var phoneNumberRegion = phoneUtil.getRegionCodeForNumber(number);
            var phoneNumberType = phoneUtil.getNumberType(number);

            isValidMobile = phoneUtil.isValidNumberForRegion(number, phoneNumberRegion);
            if (isValidMobile
                && (
                    (phoneNumberType === phoneTypes.MOBILE && bolValidateMobilePhone)
                    || (phoneNumberType === phoneTypes.FIXED_LINE && bolValidateFixedPhone)
                    || phoneNumberType === phoneTypes.FIXED_LINE_OR_MOBILE
                )
            ) {
                result = true;
            }
        }

        return result;
    },

    getCountryCodeFromInput: getCountryCodeFromInput
};